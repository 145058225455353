import React, { Component } from "react"
import "./Balloons.css"
import React_balloon from "./images/React_Balloon.png"
import Bootstrap_balloon from "./images/Bootstrap_Balloon.png"
import CSS3_balloon from "./images/CSS3_Balloon.png"
import Django_balloon from "./images/Django_Balloon.png"
import Gatsby_balloon from "./images/Gatsby_Balloon.png"
import Heroku_balloon from "./images/Heroku_Balloon.png"
import HTML5_balloon from "./images/HTML5_Balloon.png"
import Javascript_balloon from "./images/Javascript_Balloon.png"
import PostgresQL_balloon from "./images/PostgresQL_Balloon.png"
import Python_balloon from "./images/Python_Balloon.png"
import Sketch_balloon from "./images/Sketch_Balloon.png"
import Jenkins_balloon from "./images/Jenkins_Balloon.png"
import AWS_balloon from "./images/AWS_Balloon.png"
import Figma_balloon from "./images/Figma_Balloon.png"
import Ansible_balloon from "./images/Ansible_Balloon.png"
import Docker_balloon from "./images/Docker_Balloon.png"

export default class Balloons extends Component {
  render() {
    return (
      <div className="all_balloons">
        <div className="Bbig BC-3">
          <img src={React_balloon} alt="react" />
        </div>

        <div className="Bbig BC-6">
          <img src={Django_balloon} alt="django" />
        </div>

        <div className="BmediumUPUP BC-2">
          <img src={Gatsby_balloon} alt="gatsby" />
        </div>

        <div className="Bmedium BC-2">
          <img src={Jenkins_balloon} alt="jenkins" />
        </div>

        <div className="Bmedium BC-8">
          <img src={PostgresQL_balloon} alt="postgresql" />
        </div>

        <div className="Bmedium BC-3">
          <img src={AWS_balloon} alt="aws" />
        </div>

        <div className="BlittleUP BC-1">
          <img src={Javascript_balloon} alt="javascript" />
        </div>

        <div className="BlittleUP BC-6">
          <img src={Python_balloon} alt="python" />
        </div>

        <div className="BlittleUP BC-7">
          <img src={CSS3_balloon} alt="css" />
        </div>

        <div className="Blittle BC-5">
          <img src={Sketch_balloon} alt="sketch" />
        </div>

        <div className="BlittleUP BC-6">
          <img src={HTML5_balloon} alt="html" />
        </div>

        <div className="Blittle BC-2">
          <img src={Figma_balloon} alt="figma" />
        </div>

        <div className="BlittleUP BC-5">
          <img src={Ansible_balloon} alt="ansible" />
        </div>

        <div className="BlittleUP BC-4">
          <img src={Docker_balloon} alt="docker" />
        </div>
      </div>
    )
  }
}
