import React from "react"

import SEO from "../components/seo"
import Balloons from "../components/Globals/Balloons/Balloons"
import desk from "../images/developer-london-skills-night-desk.svg"

const Skills = () => (
  <div className="background_skills">
    <SEO title="Skills" />
    <div className="skills_title_container">
      <div className="skills_title">
        <h1>The tools I work with</h1>
        <p>
          Here is a collection of the some of the tools I use most regularly.
          Depending on project needs, I use Django or Wagtail to create backend
          API's. I then use React or Gatsby to build the frontend.
        </p>
      </div>
    </div>
    <div className="balloons_container">
      <Balloons />
    </div>
    <div className="developer-london-skills-night-desk" />
    {/* <div className="work_desk" /> */}
  </div>
)

export default Skills
